import * as React from "react";
import Helmet from "react-helmet";

import { Link } from "gatsby";

import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { respondTo } from "../_respondTo";

import { StaticImage } from "gatsby-plugin-image";
import logo from "../images/twg_logo.svg";

import { PageWrapper, TextWrapper } from "../components/layout";
import Navigation from "../components/navigation";
import Footer from "../components/footer";

import ContactForm from "../components/contact-form";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    font-family: sans-serif;
  }
`;

const LogoWrapper = styled.div`
  margin: auto;
  width: 25%;
  max-width: 175px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  margin: auto;
  text-align: center;
  color: #020f73;
  font-family: sans-serif;
`;

// markup
const ContactPage = () => {
  return (
    <>
      <Helmet>
        {" "}
        <title>Contact | True Wealth Group</title>
        <meta name="description" content="Contact True Wealth Group" />
        <script defer src="https://unpkg.com/@tinybirdco/flock.js" data-host="https://api.tinybird.co" data-token="p.eyJ1IjogImI3YzA5NWQxLThiMTctNGQ5Ni04ODUwLWUxNjM0YmU5NzgyOCIsICJpZCI6ICI4Njg5NGY0MC00ZjBlLTRiMGMtYTI2YS0xMmNkZTBlYjEyOWMifQ.cXvEL-7r0R4anvPPduWPoDsUKPPZeyI54Z2BuA_L5E8"></script>
      </Helmet>
      <PageWrapper>
        <Navigation></Navigation>
        <GlobalStyle />
        <StaticImage
          alt="Background image of person walking up path on a mountain"
          src={"../images/bg-img.jpg"}
          formats={["auto", "webp", "avif"]}
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "-1",
          }}
          loading="eager"
        />
        <LogoWrapper>
          <img
            src={logo}
            style={{ width: "100%", paddingTop: "25%", aspectRatio: "1/1" }}
            alt="True Wealth Group logo"
          />
        </LogoWrapper>{" "}
        <ContentWrapper>
          <h1>Contact</h1>
        </ContentWrapper>
        <TextWrapper>
          <ContactForm />
        </TextWrapper>
      </PageWrapper>
      <Footer></Footer>
    </>
  );
};

export default ContactPage;

export const Head = () => (
  <>
    <title>Contact | True Wealth Group</title>
    <meta name="description" content="Contact True Wealth Group" />
    <meta name="keywords" content="True Wealth Group" />
  </>
);
